import React from 'react'
import { Layout, Menu } from 'antd'
import { Link, Redirect } from "react-router-dom";
const sideDashboardImg = require('../../assets/dashboard.png')
const sideGiftImg = require('../../assets/giftCard.png')
const sideOffsetImg = require('../../assets/Offset.png')
const sidePlantTreeImg = require('../../assets/plantTree.png')
const sideProjectsTreeImg = require('../../assets/projects.png')
import { LOGO, SIDEARROW } from '../assets'
import IndividualNav from '../IndividualNav.web'

const { Header, Content, Footer, Sider } = Layout
const IndvLayout = (props: any) => {

  const location = window.location.pathname
  let loginDetails:any = localStorage.getItem("UserDetails")
  const loginFlag =  JSON.parse(loginDetails)?.type;
  
  return (
    loginFlag !== "Individual" ? <Redirect to="/login"/> : 
    <>  
    {loginFlag === "Individual" ?  
      <Layout>
        <Sider
          breakpoint='lg'
          collapsedWidth='0'
          onBreakpoint={(broken) => {
            console.log(broken)
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type)
          }}
          className='indv-dash-sidebar'
        >
          <div className='indv-logo-head '>
            <Link to="/">
            <img src={LOGO} alt='LOGO' />
            </Link>
          </div>
          <Menu
            theme='dark'
            mode='inline'
            // defaultSelectedKeys={['4']}
          >
            <Menu.Item key='1'>
              <Link to='/individual/dashboard'>
                <span className='indv-dash-side-img'>
                  <img src={sideDashboardImg} />
                </span>{' '}
                Dashboard
              </Link>
               {location==='/individual/dashboard'? <img src={SIDEARROW} alt="" className='image_side_nav'/>:null}
            </Menu.Item>
            <Menu.Item key='2'>
              <Link to='/individual/offset-onetime'>
                <span className='indv-dash-side-img'>
                  <img src={sideOffsetImg} />
                </span>{' '}
                Offset
                {location==='/individual/offset-onetime'? <img src={SIDEARROW} alt="" className='image_side_nav'/>:null}
              </Link>
            </Menu.Item>
            <Menu.Item key='3'>
              <Link to='/individual/plant-tree'>
                <span className='indv-dash-side-img'>
                  <img src={sidePlantTreeImg} />
                </span>{' '}
                Plant a Tree
                {location==='/individual/plant-tree'? <img src={SIDEARROW} alt="" className='image_side_nav'/>:null}
              </Link>
            </Menu.Item>
            <Menu.Item key='4'>
              <Link to='/individual/giftCard'>
                <span className='indv-dash-side-img'>
                  <img src={sideGiftImg} />
                </span>{' '}
                Gift a Card
                {location==='/individual/giftCard'? <img src={SIDEARROW} alt="" className='image_side_nav'/>:null}
              </Link>
            </Menu.Item>
            <Menu.Item key='5'>
              <Link to='/individual/project'>
                <span className='indv-dash-side-img'>
                  <img src={sideProjectsTreeImg} />
                </span>{' '}
                Projects
                {location==='/individual/project'? <img src={SIDEARROW} alt="" className='image_side_nav'/>:null}
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>

        <Layout className='indv-layout'>
          <Header className='site-layout-sub-header-background'>
            {' '}
            <IndividualNav />
          </Header>

          <Content>
            <div className='site-layout-background' style={{ minHeight: 360 }}>
              {props.children}
            </div>
          </Content>
        </Layout>
      </Layout>
    : <Redirect to={{ pathname: '/login' }} />}
    </>
  )
}

export default IndvLayout
