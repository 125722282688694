export const CART = require("../assets/cart.png");
export const LOGO = require("../assets/Logo.png");
export const SIDEARROW = require("../assets/sideArrow.png");
export const USERN0tLOGIN = require("../assets/UserNotLogin.png");
export const DASHBOARD = require("../assets/dashboard.png");
export const GIFTCARD = require("../assets/giftCard.png");
export const OFFSET = require("../assets/Offset.png");
export const PLANTTREE = require("../assets/plantTree.png");
export const PROJECTS = require("../assets/projects.png");
export const FIRSTBLOCK = require("../assets/firstblock.png");
export const MAP = require("../assets/map.png");
export const FIRSTSQUARE = require("../assets/firstsquare.png");
export const SECONDSQUARE = require("../assets/secondsquare.png");
export const WINDMIL = require("../assets/windmil.png");
export const PAGES = require("../assets/pages.png");
export const LOCATION = require("../assets/location.png");
export const CARBONWIND = require("../assets/carbonWind.png");
export const technicalSupport = require("../assets/technical-support.png");
export const Planttreegift = require("../assets/img_planttreegift.png");

export const articleIMG = require("../assets/article_img1.png");

export const GreenSpecs = require("../assets/offsetImg/green_specs.png");
export const GreenCar = require("../assets/offsetImg/green_car.png");
export const GreenFlight = require("../assets/offsetImg/green_flight.png");
export const GreenHouse = require("../assets/offsetImg/green_house.png");

export const packageMaintenance = require("../assets/packageMaintenance.png");
export const packagePlant = require("../assets/packagePlant.png");
export const packageGrowth = require("../assets/packageGrowth.png");
export const miyawakiGrowth = require("../assets/miyawaki_growth.png");
export const miyawakiCarbon = require("../assets/miyawaki_carbon.png");
export const miyawakiDiversity = require("../assets/miyawaki_diversity.png");
export const miyawakiGreen = require("../assets/miyawaki_green.png");
export const UserProfile = require("../assets/userProfile.png");

export const climateCertificate = require("../assets/climate_certificate.png");
export const EarnClimateCertificate = require("../assets/earn_climate_certificate.png");

export const PaymentSuccessImg = require("../assets/payment-success-card.png");
export const CircleCrosh = require("../assets/circle_crosh.png");
export const Icon_Close = require("../assets/Icon_Close.png");
export const SbiCard = require("../assets/sbiCard.png");

export const PROJECTCLIMATEWIND = require("../assets/projectclimatewind.png");
export const Climate17Goals = require("../assets/Climate17Goals.png");
export const climateicon1 = require("../assets/climategoldicon.png");
export const climateicon2 = require("../assets/climateicon2.png");
export const climateicon3 = require("../assets/climateicon3.png");
export const Climateicon3old = require("../assets/climateicon3-old.png");
export const goal1 = require("../assets/goal1.png");
export const goal2 = require("../assets/goal2.png");
export const goal3 = require("../assets/goal3.png");
export const goal4 = require("../assets/goal4.png");
export const goal5 = require("../assets/goal5.png");
export const goal6 = require("../assets/goal6.png");
export const goal7 = require("../assets/goal7.png");
export const goal8 = require("../assets/goal8.png");
export const goal9 = require("../assets/goal9.png");
export const goal10 = require("../assets/goal10.png");
export const goal11 = require("../assets/goal11.png");
export const goal12 = require("../assets/goal12.png");
export const goal13 = require("../assets/goal13.png");
export const goal14 = require("../assets/goal14.png");
export const goal15 = require("../assets/goal15.png");
export const goal16 = require("../assets/goal16.png");
export const goal17 = require("../assets/goal17.png");
export const goal18 = require("../assets/goal18.png");

export const ArticleImg1 = require("../assets/article_img1.png");
export const ArticleImg2 = require("../assets/article_img2.png");
export const ArticleImg3 = require("../assets/article_img3.png");
export const UserProfile1 = require("../assets/userProfile1.png");
export const UserProfile2 = require("../assets/userProfile2.png");
export const UserProfile3 = require("../assets/userProfile3.png");

export const Abou_mission = require("../assets/abou_mission.png");
export const Abou_vision = require("../assets/abou_vision.png");

export const IndvPlantImg = require("../assets/indvPlantImg.png");
export const IndvPlanttreeImg = require("../assets/indvPlanttreeImg.png");
export const locationMap = require("../assets/locationMap.png");
export const Icon_call = require("../assets/icon_call.png");
export const Icon_mail = require("../assets/icon_mail.png");
export const Plush = require("../assets/FlightPlush.png");
export const partnerCarousel1 = require("../assets/home/partner-1.png");
export const partnerCarousel2 = require("../assets/home/Partner-2.png");
export const partnerCarousel3 = require("../assets/home/Partner-3.png");
export const OURPARTNERS = require("../assets/home/ourPartner.png");

/// -----------------Screen Loader------------------------
export const ScreenLoader = require("../assets/Loading_gif.gif");
export const DefaultImg = require("../assets/defaultImg.jpg");

// --------------Landing page images-----------------
export const getStartedImg = require("../assets/home_getstarted.png");
export const bannerImg = require("../assets/carbon_bannerImg.png");
export const offsetImg = require("../assets/indvRecycle.png");
export const plantImg = require("../assets/idvPlant.png");
export const offsetBanner = require("../assets/indvOffImg.png");
export const BusinessCar = require("../assets/BusinessCar.png");
export const Uparrow = require("../assets/Uparrow.png");
export const Downarrow = require("../assets/Downarrow.png");

//Bussiness Dashboard image
export const ORDER = require("../assets/Order.png");
export const ONEOFFSET = require("../assets/OneOffset.png");
export const SUBOFFSET = require("../assets/subOffset.png");
export const BUSSINESSSIDEARROW = require("../assets/bussinessSideArrow.png");
export const bussinesLayoutImage1 = require("../assets/bussinessSideArrow.png");
export const bussinesLayoutImage2 = require("../assets/bussinessSideArrow.png");

//Individual Card Images
export const VisaImg = require("../assets/CardImg/visaCard.png");
export const UnionpCard = require("../assets/CardImg/unionpCard.png");
export const MasterCard = require("../assets/CardImg/masterCard.png");
export const AmericanExpressCard = require("../assets/CardImg/AmericanExpressCard.png");

//Bussiness marketing Toolkit image
export const BlackTree = require("../assets/impactBlackTree.png");
export const BlueTree = require("../assets/impactBlueTree.png");

//our Approach
export const CarbonOffsetVector1 = require("../assets/ourApproach/Vector 5.svg")
export const CarbonOffsetVector2 = require("../assets/ourApproach/Vector 3.svg")
export const CarbonOffsetVector3 = require("../assets/ourApproach/Vector 2.svg")
export const CarbonOffsetVector4 = require("../assets/ourApproach/Vector 4.svg")
export const individual = require("../assets/ourApproach/individual.svg")
export const organization = require("../assets/ourApproach/organization.svg")
export const EARTH = require("../assets/ourApproach/Earth.svg")
export const WINDMILL = require("../assets/ourApproach/WindMillIcon.svg")
export const RECYCLE = require("../assets/ourApproach/Recycle.svg")
export const SAVEICON = require("../assets/ourApproach/SavingIcon.svg")
export const RENEWABLEENERGY = require("../assets/ourApproach/RenewableEnergyIcon.svg")
export const GreenArrow = require("../assets/ourApproach/arrow-green.png")
export const BlueArrow = require("../assets/ourApproach/arrow-blue.png")

//homepageimage
export const NextStep1 = require("../assets/home/nextStep1.png")
export const NextStep2 = require("../assets/home/nextStep2.png")
export const NextStep3 = require("../assets/home/nextStep3.png")
export const NextStep4 = require("../assets/home/nextStep4.png")
export const NextStep5 = require("../assets/home/nextStep5.png")
export const NextStep6 = require("../assets/home/nextStep6.png")
export const climateAction1 = require("../assets/home/climateAction1.png")
export const climateAction2 = require("../assets/home/climateAction2.png")
export const climateAction3 = require("../assets/home/climateAction3.png")
export const climateAction4 = require("../assets/home/climateAction4.png")
export const vision1 = require("../assets/home/vision1.png")
export const vision2 = require("../assets/home/vision2.png")
export const vision3 = require("../assets/home/vision3.png")
export const vision4 = require("../assets/home/vision4.png")
export const DeleteTrash = require('../assets/deleteTrash.png')
export const IndvIcon = require("../assets/home/indv-icon.svg")
export const BusinessIcon = require("../assets/home/business-icon.svg")
export const AvtaarImg = require("../assets/aboutUs/img_avatar.png")
export const FacebookImg = require("../assets/aboutUs/facebook-filled.svg")
export const InstaImg = require("../assets/aboutUs/instagram-filled.svg")
export const TwitterImg = require("../assets/aboutUs/twitter-filled.svg")
export const linkedinImg = require("../assets/aboutUs/linkedIn.png")
export const MountainBg = require("../assets/aboutUs/Mountain_windmill_Bg.svg")

export const NewGiftCardImg = require("../assets/Gift_cart_green_img.png")
export const projectscreenshoot2 = require('../assets/projectscreenshoot2.png')
export const projectscreenshoot3 = require('../assets/projectscreenshoot3.png')
export const waves = require('../assets/waves.png')
export const ekopointleftdown = require('../assets/arrowLeft.png')
export const ekopointrightdown = require('../assets/arrowRight.png')

//landing page project images 
export const projectBlueCarbon = require("../assets/home/ProjectBlueCarbon.jpg")
export const projectCarbonRemoval = require("../assets/home/ProjectCarbonRemoval.jpg")
export const projectCommunity1 = require("../assets/home/ProjectCommunity.png")
export const projectEnergy = require("../assets/home/ProjectEnergyEfficiency.jpg")
export const projectNatureBased = require("../assets/home/ProjectNaureBasedSolutions.png")
export const projectRenewable = require("../assets/home/ProjectRenewable.jpg")
